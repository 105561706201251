:root .inputfocus:focus {
  border: 1px solid lightskyblue;
}
:root .inputfocus {
  border: 1px solid lightgrey;
  background: white;
}
:root .hover:hover {
  cursor: pointer;
}

:root .code-textarea {
  background-color: #f8f9fa;
  border-left: 4px solid #2fc52a;
  border-right: 1px solid #b1b7c1;
  font-size: 12px;
}
:root .code-textarea-eg {
  background-color: #f8f9fa;
  border-left: 4px solid #2a70c5;
  border-right: 1px solid #b1b7c1;
}

:root .code-textarea:disabled {
  background-color: #f8f9fa;
}
:root .code-textarea:focus {
  box-shadow: none;
}
:root .code-textarea-eg:disabled {
  background-color: #f8f9fa;
}
:root .code-textarea-eg:focus {
  box-shadow: none;
}
:root .font-sm {
  font-size: 10px;
}
:root .font-md {
  font-size: 13px;
}
:root .font-lg {
  font-size: 14px;
}
:root .icon-color {
  color: #333333;
}
:root .icon-color:hover {
  color: #359aff;
  cursor: pointer;
}
:root .button-tooltip {
  box-shadow: rgba(0, 0, 0, 0.44) 0px 3px 8px;
  /* border: 1px solid rgb(220, 220, 220); */
  border-radius: 5px;
  z-index: 10;
  background-color: white !important;
  color: red;
}
.button-33 {
  background-color: #c2fbd7;
  border-radius: 100px;
  box-shadow: rgba(44, 187, 99, 0.2) 0 -25px 18px -14px inset,
    rgba(44, 187, 99, 0.15) 0 1px 2px, rgba(44, 187, 99, 0.15) 0 2px 4px,
    rgba(44, 187, 99, 0.15) 0 4px 8px, rgba(44, 187, 99, 0.15) 0 8px 16px,
    rgba(44, 187, 99, 0.15) 0 16px 32px;
  color: green;
  cursor: pointer;
  display: inline-block;
  font-family: CerebriSans-Regular, -apple-system, system-ui, Roboto, sans-serif;
  padding: 7px 20px;
  text-align: center;
  text-decoration: none;
  transition: all 250ms;
  border: 0;
  font-size: 16px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-33:hover {
  box-shadow: rgba(44, 187, 99, 0.35) 0 -25px 18px -14px inset,
    rgba(44, 187, 99, 0.25) 0 1px 2px, rgba(44, 187, 99, 0.25) 0 2px 4px,
    rgba(44, 187, 99, 0.25) 0 4px 8px, rgba(44, 187, 99, 0.25) 0 8px 16px,
    rgba(44, 187, 99, 0.25) 0 16px 32px;
  transform: scale(1.05) rotate(-1deg);
}


:root .visible-scroll {
  overflow: hidden !important;
}

:root .visible-scroll:hover {
  overflow: auto !important;
}