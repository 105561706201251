.chat-container {
  /* margin-left: 370px; */
  /* margin-right: 10px; */
  /* margin-bottom: 10px; */
  /* margin-top: 10px; */
  /* background-image: url("../assets/images/home_bg.avif"); */
  text-align: left;
  transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
  /* object-fit: contain; */
  height: 90vh;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding-top: 20px;
  width: 100%;

  border: 1px solid lightgray;

  padding-bottom: 80px;
  overflow-y: scroll;
}

.chat-container-search {
  /* margin-left: 370px; */
  /* margin-right: 10px; */
  /* margin-bottom: 10px; */
  /* margin-top: 10px; */
  /* background-image: url("../assets/images/home_bg.avif"); */
  text-align: left;
  transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
  /* object-fit: contain; */
  height: 90vh;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding-top: 60px;
  width: 100%;

  border: 1px solid lightgray;

  padding-bottom: 80px;
  overflow-y: scroll;
}

:root .new-sidebar {
  /* left: 10px; */
  background: rgb(255, 255, 255);
  z-index: 12;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  overflow: hidden;
  /* margin: 10px;
  margin-top: 10px; */

  height: 90vh;
  border-radius: 10px;
  border: 1px solid lightgray;
}

:root .new-input-form {
  position: absolute;
  bottom: 0;
  width: 98%;
  padding-bottom: 20px;
  z-index: 11;
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  backdrop-filter: blur(7px);
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
}
:root .new-input-form-search {
  position: absolute;
  top: 0;
  width: 98%;
  padding-top: 20px;
  z-index: 11;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
  align-items: center;
  backdrop-filter: blur(7px);
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
}
.title-sidebar {
  color: #f88130;
  font-size: 16px;
  margin-left: 10px;
  font-weight: 500;
  font-family: system-ui;
}

.rolling-chat-new {
  cursor: pointer;
  width: 25px;
  height: 25px;
  text-align: left;
  overflow: hidden;
  transition: all 0.5s ease;
  white-space: nowrap;
  margin: 0px;
  color: #f77e34;
}
.rolling-chat-new:hover {
  width: 110px;
  display: flex;
  align-items: center;
  transition: all 0.5s ease;
}
.rolling-chat-new ::after {
  font-weight: bold;
  padding: 4px 0;
}
.rolling-chat-new:hover {
  display: block;
}
.rolling-buttons-new {
  cursor: pointer;
  width: 32px;
  height: 32px;
  text-align: left;
  overflow: hidden;
  transition: all 0.5s ease;
  white-space: nowrap;
  margin: 0px;
}
.rolling-buttons-new:hover {
  width: 100px;
  transition: all 0.5s ease;
}

/* animation */

.scale-up-center {
  -webkit-animation: scale-up-center 300ms ease-in-out forwards;
  animation: scale-up-center 300ms ease-in-out forwards;
}
/* @-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
} */
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
