:root .inputfocus:focus {
  border: 1px solid lightskyblue;
}
:root .inputfocus {
  border: 1px solid lightgrey;
  background: white;
}

:root .inputfocus-jsw:focus {
  border: 1px solid rgb(23,70,158);
  box-shadow:rgb(23,70,158) 0px 6px 12px -2px, rgba(23, 70, 158, 0.766) 0px 3px 7px -3px;
}
:root .inputfocus-jsw:active {
  border: 1px solid rgb(23,70,158);
  box-shadow:rgb(23,70,158) 0px 6px 12px -2px, rgba(23, 70, 158, 0.766) 0px 3px 7px -3px;
}
:root .inputfocus {
  border: 1px solid lightgrey;
  background: white;
}
:root .hover:hover {
  cursor: pointer;
}

:root .code-textarea {
  background-color: #f8f9fa;
  border-left: 4px solid #2fc52a;
  border-right: 1px solid #b1b7c1;
  font-size: 12px;
}
:root .code-textarea-eg {
  background-color: #f8f9fa;
  border-left: 4px solid #2a70c5;
  border-right: 1px solid #b1b7c1;
}

:root .code-textarea:disabled {
  background-color: #f8f9fa;
}
:root .code-textarea:focus {
  box-shadow: none;
}
:root .code-textarea-eg:disabled {
  background-color: #f8f9fa;
}
:root .code-textarea-eg:focus {
  box-shadow: none;
}
:root .font-sm {
  font-size: 10px;
}
:root .font-md {
  font-size: 0.78rem;
}
:root .font-lg {
  font-size: 14px;
}
:root .icon-color {
  color: #333333;
}
:root .icon-color:hover {
  color: #359aff;
  cursor: pointer;
}
:root .button-tooltip {
  box-shadow: rgba(0, 0, 0, 0.44) 0px 3px 8px;
  /* border: 1px solid rgb(220, 220, 220); */
  border-radius: 5px;
  z-index: 10;
  background-color: white !important;
  color: red;
}
/* CSS */
.button-6 {
  align-items: center;
  background-color: #cbfff3;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.85);
  cursor: pointer;
  display: inline-flex;
  font-family: "Open Sans", "Sans Serif", sans-serif;
  font-size: 12px;
  justify-content: center;
  margin: 0;
  padding: 5px;
  position: relative;
  text-decoration: none;
  transition: all 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  width: auto;
}
.pop-button {
  transition: all 250ms;
  touch-action: manipulation;
  user-select: none;
  -webkit-user-select: none;
}
.pop-button:hover,
.pop-button:focus {
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
  /* color: rgba(0, 0, 0, 0.65); */
}

.pop-button:hover {
  transform: translateY(-1px);
}

.pop-button:active {
  /* background-color: rgb(173, 173, 244); */
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
  color: rgba(0, 0, 0, 0.65);
  transform: translateY(0);
}
.heh {
  max-height: 40px !important;
}
