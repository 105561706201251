.slider-container {
    padding: 10px;
    max-width: 400px;
    margin: 0 auto;
    text-align: center;
  }
  
  .slider-label {
    display: block;
    margin-bottom: 10px;
    font-size: 1.2em;
  }
  
  .slider {
    width: 100%;
    margin: 20px 0;
  }
  
  .slider-labels {
    display: flex;
    justify-content: space-between;
    font-size: 0.9em;
  }
  