.button-10-sbi {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6px 14px;
  font-family: "Open Sans", "Sans Serif", sans-serif;
  border-radius: 30px;
  border: none;
  color: #fff;
  background: linear-gradient(180deg, #9e76fb 0%, #6534d9 100%);
  background-origin: border-box;
  box-shadow: 0px 0.5px 1.5px rgba(54, 122, 246, 0.25),
    inset 0px 0.8px 0px -0.25px rgba(255, 255, 255, 0.2);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-10-sbi:focus {
  box-shadow: inset 0px 0.8px 0px -0.25px rgba(255, 255, 255, 0.2),
    0px 0.5px 1.5px rgba(54, 122, 246, 0.25),
    0px 0px 0px 3.5px rgba(101, 52, 217, 0.5);
  outline: 0;
}
/* CSS */
.button-19 {
  appearance: button;
  background-color: #eeb327;
  border: solid transparent;
  border-radius: 16px;
  border-width: 0 0 4px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-family: "Open Sans", "Sans Serif", sans-serif;
  font-size: 15px;
  font-weight: 700;
  margin: 0;
  outline: none;
  overflow: visible;
  padding: 8px 15px;
  text-align: center;
  text-transform: uppercase;
  touch-action: manipulation;
  transform: translateZ(0);
  transition: filter 0.2s;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  width: max-content;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.button-19:after {
  background-clip: padding-box;
  background-color: #fdc83d;
  border: solid transparent;
  border-radius: 16px;
  border-width: 0 0 4px;
  bottom: -4px;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

.button-19:main,
.button-19:focus {
  user-select: auto;
}

.button-19:hover:not(:disabled) {
  filter: brightness(1.1);
  -webkit-filter: brightness(1.1);
}

.button-19:disabled {
  cursor: auto;
}

.button-19:active {
  border-width: 4px 0 0;
  background: none;
}

.chat-mapping {
  margin-right: 370px;
  margin-left: 10px;
  margin-bottom: 10px;
  text-align: left;
  transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
  background-color: #ffffff;
  height: 88vh;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding-top: 20px;
  background-image: url("./assets/images/pattern.svg");
  position: relative;
  padding-bottom: 80px;
}
.rolling {
  cursor: pointer;
  width: 22px;
  height: 22px;
  text-align: left;
  overflow: hidden;
  transition: all 0.5s ease;
  white-space: nowrap;
  color: #f77e34;
}
.rolling:hover {
  width: 80px;
  transition: all 0.5s ease;
}
.rolling ::after {
  font-weight: bold;
  padding: 4px 0;
}
.rolling:hover {
  display: block;
}

.rolling-chat {
  cursor: pointer;
  width: 32px;
  height: 32px;
  text-align: left;
  overflow: hidden;
  transition: all 0.5s ease;
  white-space: nowrap;
  margin: 0px;
  color: #f77e34;
}
.rolling-chat:hover {
  width: 100px;
  transition: all 0.5s ease;
}
.rolling-chat ::after {
  font-weight: bold;
  padding: 4px 0;
}
.rolling-chat:hover {
  display: block;
}

.auth-wrapper-sbi {
  width: auto;
  display: flex;
}

.navbar-light-sbi {
  height: 50px;
  background-color: #ffffff;
  /* box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2); */
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 5px;
}

.auth-wrapper-sbi .form-control:focus {
  outline: 1px solid #6534d9;
  border-color: #6534d9;
  box-shadow: none;
}

.auth-wrapper-sbi h3 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
}
.custom-control-label {
  font-weight: 400;
}
