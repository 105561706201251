.carousel-container {
  height: 95vh !important;
  width: 100%;
  position: relative;
}
.trend-carousel {
  height: 95vh !important;
  position: relative;
}
.trend-carousel .carousel-inner {
  height: 100% !important;
}

.trend-carousel .carousel-indicators {
  bottom: 36%;
}
.trend-carousel-bg {
  position: relative;
  width: 100%;
  height: 100%;
  filter: blur(7px);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.trend-img-container {
  height: 50%;
  width: 50%;
  position: absolute;
  top: 5%;
  z-index: 2000;
  right: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.12);
  overflow: visible;
  clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
}

.trend-img {
  width: 98%;
  height: 98%;
  object-fit: contain;
  cursor: zoom-in;
}
.trend-img:hover {
  transform: scale(1.02);
}

.trend-carousel-caption {
  position: absolute;
  height: 55vh;
  width: 32%;
  top: 0px;
  left: 13%;
  z-index: 2000;
}
.trend-carousel-caption .caption-bg {
  filter: blur(10px);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(125, 105, 189, 0.41);
  clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
}
.caption-title-container {
  position: relative;
  background-color: rgba(5, 5, 5, 0.19);
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.caption-title {
  font-size: 34px;
  text-align: start;
  position: relative;
  padding-left: 8%;
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;
}

.caption-stroked-title {
  color: rgba(125, 105, 189, 0.26);
  -webkit-text-stroke: 2px rgb(237, 235, 242);
  text-stroke: 2px rgb(125, 105, 189);
}

.custom-btn {
  background-color: rgb(139, 123, 192);
  color: rgb(255, 255, 255);
  border: 1px solid white;
  font-size: 18px;
  outline: none;
}
.custom-btn:hover,
.custom-btn:focus-visible {
  background-color: rgb(139, 123, 192);
  color: rgb(255, 255, 255);
  opacity: 0.8;
  border: 1px solid whitesmoke;
}
:root .custom-btn:active {
  background-color: rgb(139, 123, 192);
  color: rgb(255, 255, 255);
  border: 1px solid whitesmoke;
  opacity: 0.8;
}
:root .custom-btn:disabled {
  background-color: rgb(139, 123, 192);
  color: rgb(255, 255, 255);
  border: 1px solid whitesmoke;
  opacity: 0.5;
}

.img-enlargable-container {
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* Adjust for dynamic cursor based on dragging state */
  overflow: hidden;
}

.zoom-btn-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  position: absolute;
  bottom: 15px;
  background: rgba(0, 0, 0, 0.8);
  z-index: 10001;
  border-radius: 4px;
  padding: 5px 10px;
}
.zoom-btn-container .zoom-btn {
  color: white;
  cursor: pointer;
  width: 25px;
  height: 25px;
}

.close-btn-container {
  position: absolute;
  top: 0;
  right: 10px;
  display: flex;
  justify-content: end;
  gap: 5px;
  align-items: center;
  background: transparent;
  padding: 8px;
  z-index: 10001;
}
.close-btn-container .close-btn {
  background: none;
  color: white;
  border: none;
  font-size: 18px;
  cursor: pointer;
  padding: 0 5px;
}
.zoom-img-container {
  max-width: 90vw;
  max-height: 90vh;
}
.zoom-img-container .zoom-img {
  max-width: 100%;
  max-height: 100%;
  user-select: none;
  pointer-events: none;
}
.overlay {
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
