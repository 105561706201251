/* src/Chat.css */




.chat-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem; /* Add some spacing between messages */
}

.message {
  padding: 1rem;
  border-radius: 4px;
  background-color: #eee;
}

.sent {
  background-color: #e0f7fa; /* Light blue for sent messages */
  text-align: right;
}

.received {
  background-color: #fff; /* White for received messages */
  text-align: left;
}

.chat-bubble {
    max-width: 60%;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 10px;
  }
  
  .chat-bubble-left {
    background-color: #e1f3fb;
    text-align: left;
  }
  
  .chat-bubble-right {
    background-color: #f1f1f1;
    text-align: right;
  }

  
  