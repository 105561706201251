/* ChatBubble.css */
.chat-bubble {
  overflow-y: auto;
  height: 30px;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  backdrop-filter: blur(2.7px);
}

.chat-message {
  /* background-color: rgba(241, 240, 240, 1); */
  background-color: white;
  border-radius: 15px;
  padding: 5px 10px;
  margin-bottom: 5px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  /* margin-left: 20px; */
  max-width: 80%;
}

.chat-message.user {
  background: linear-gradient(
    135deg,
    rgba(0, 107, 178, 1) 0%,
    rgba(52, 158, 211, 1) 90%
  );
  backdrop-filter: blur(2.7px);
  color: #fff;
  align-self: flex-end;
}

.chat-message.error {
  background: white;
  color: #f57113;
  align-self: flex-end;
  border: 1px solid red;
  backdrop-filter: blur(2.7px);
  font-size: 14px;
}

.chat-message.other {
  /* background: rgba(236, 243, 254, 1); */
  background: white;
  color: #086eb5;
  align-self: flex-end;
  backdrop-filter: blur(2.7px);
  border: 1px solid #eef4f8;
}

.chat-response {
  margin-bottom: 5px;
  max-width: 100%;
  justify-content: end;
  /* display: flex; */
}

.avatar {
  box-shadow: rgba(28, 28, 50, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.audio-message {
  /* background-color: rgba(241, 240, 240, 1); */
  background-color: white;
  border-radius: 5px;
  padding: 5px 10px;
  margin-bottom: 5px;
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, */
  /* rgba(0, 0, 0, 0.3) 0px 3px 7px -3px; */
  /* margin-left: 20px; */
  /* color: #178717; */
  color: #7c0d69;
}

.transcript-message {
  /* background-color: rgba(241, 240, 240, 1); */
  background-color: white;
  border-radius: 5px;
  padding: 5px 10px;
  margin-bottom: 5px;
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, */
  /* rgba(0, 0, 0, 0.3) 0px 3px 7px -3px; */
  /* margin-left: 20px; */
  color: #437ddb;
}

span.Typewriter__wrapper {
  font-weight: 400;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.bottom-to-up {
  animation: 0.4s bottom-to-up forwards;
}

@keyframes bottom-to-up {
  from {
    transform: translateY(48px);
  }

  to {
    transform: translateX(0);
  }
}

.right-to-left {
  animation: 0.4s left-to-right forwards;
}

@keyframes right-to-left {
  from {
    transform: translateX(-100vw);
  }

  to {
    transform: translateX(0);
  }
}

.left-to-right {
  animation: 0.4s right-to-left forwards;
}

@keyframes left-to-right {
  from {
    transform: translateX(100vw);
  }

  to {
    transform: translateX(0);
  }
}

.top-to-bottom {
  animation: 0.4s top-to-bottom forwards;
}

@keyframes top-to-bottom {
  from {
    transform: translateY(-48px);
  }

  to {
    transform: translateY(0);
  }
}

/* jsw */

.chat-message-jsw {
  background-color: #f1f0f0;
  border-radius: 10px;
  padding: 8px 12px;
  margin-bottom: 5px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  margin-left: 20px;
}

.chat-message-jsw.other {
  background-color: white;
  color: #086eb5;
  align-self: flex-end;
  border: 1px solid white;
}

.chat-message-jsw.user {
  /* background: linear-gradient(to left, #0fa3ff 10%, #005bea 90%); */
  background-color: rgb(186, 211, 255);
  /* border: 0.5px solid rgb(23,70,158); */
  backdrop-filter: blur(2.7px);
  color: #2b2b2b;
  align-self: flex-end;
  background-image: linear-gradient(
    120deg,
    rgb(186, 211, 255) 0%,
    #c2e9fb 100%
  );
}

:root .nav-link-jsw {
  color: rgb(23, 70, 158);
}
:root .nav-link-jsw:active {
  color: rgb(23, 70, 158);
}
:root .offcanvas-header {
  background-color: rgb(192 230 255);
  padding: 10px;
}
