.gallery {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 10px;
  padding: 10px;
}

.galleryItem {
  width: fit-content;
  position: relative;
  overflow: hidden;
}

.galleryItem:hover {
  opacity: 0.7;
}
.text-style-btn {
  color: rgb(88, 88, 88);
}
.text-style-btn:hover {
  background-color: rgba(127, 106, 198, 0.1);
}

.text-style-btn-active {
  background-color: rgba(127, 106, 198, 0.1);
  color: rgb(128, 106, 198);
}

:root .tab-link {
  color: rgb(142, 142, 142);
}
:root .tab-link-genrator {
  color: rgb(73, 73, 73);
  font-size: 14px;
  font-weight: 500;
}
