.bg-setup-img {
  /* background-image: url("https://img.freepik.com/free-vector/ai-technology-brain-background-vector-digital-transformation-concept_53876-117812.jpg?t=st=1725528989~exp=1725532589~hmac=a3a4cc8e322429af82ea44c73819feb88ba77639c322730501dabad43a7a16f6&w=2000"); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.button-10 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6px 14px;
  font-family: -apple-system, BlinkMacSystemFont, "Roboto", sans-serif;
  border-radius: 6px;
  border: none;

  color: #fff;
  background: linear-gradient(
    180deg,
    rgb(166, 144, 238) 0%,
    rgb(133, 116, 190) 100%
  );
  background-origin: border-box;
  box-shadow: 0px 0.5px 1.5px rgba(54, 122, 246, 0.25),
    inset 0px 0.8px 0px -0.25px rgba(255, 255, 255, 0.2);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-10:focus {
  box-shadow: inset 0px 0.8px 0px -0.25px rgba(255, 255, 255, 0.2),
    0px 0.5px 1.5px rgba(54, 122, 246, 0.25),
    0px 0px 0px 3.5px rgba(58, 108, 217, 0.5);
  outline: 0;
}
.auth-buttons {
  width: 30px;
  height: 30px;
  margin: 2px;
  padding: 2px;
}
