.watermark {
  position: absolute;
  z-index: 0;
  background: transparent;
  display: block;
  min-height: 70%;
  min-width: 70%;
  top: 10%;

  color: rgba(8, 110, 181, 0.08);
}
