:root {
  --datageir-scrollbar-size: 3px;
  --datageir-alert-height: 40px;
}
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: skyblue;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: lightblue;
}
