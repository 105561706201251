h1 {
  grid-area: title;
  font-size: 2em;
  margin: 0 0 1rem;
  font-family: serif;
  position: relative;
  padding-top: var(--box-y-padding);
  padding-left: var(--box-x-padding);

  &::after {
    content: "";
    position: absolute;
    width: 6rem;
    background-color: hsl(0, 0%, 80%);
    height: 2px;
    top: 0;
    left: 0;

    @media (min-width: 40em) {
      left: var(--box-x-padding);
      top: var(--box-y-padding);
    }
  }
}

.content {
  grid-area: content;
  padding: var(--box-y-padding) var(--box-x-padding);
  color: hsl(0, 0%, 30%);

  p {
    margin: 0 0 var(--box-y-padding);
    line-height: 1.5;
  }

  a {
    text-decoration: none;
    border: 1px solid hsl(0, 0%, 70%);
    border-radius: 1em;
    padding: 1em 2em;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 700;
    letter-spacing: 0.05em;
    color: inherit;
    display: inline-block;
    background-color: white;
  }
}

.img {
  grid-area: img;
  position: relative;

  img {
    max-width: 100%;
    vertical-align: middle;
    box-shadow: 0 0.2em 1.2em hsla(20, 20%, 20%, 0.1);
  }

  a {
    position: absolute;
    left: 0;
    bottom: 0;
  }
}

.attribution {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: #ea4c89;
  color: white;
  padding: 0.5em 1em;
}

.active-date {
  width: 80%;
  background-color: rgba(139, 123, 192, 0.1);
  border: 1px solid rgb(93, 74, 155);
  color: rgb(93, 74, 155);
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

@import url("https://fonts.googleapis.com/css?family=Lato:200,400|Playfair+Display");

.wrapper-hotel {
  float: left;
  width: 30%;
  min-width: 200px;
  /* background-color: #f1f1f1; */
  margin: 1%;
  box-sizing: border-box;
  /* box-shadow: 0 1px 30px rgba(0, 0, 0, 0.12), 0 3px 5px rgba(0, 0, 0, 0.24); */
  text-align: center;
}
.wrapper-hotel > h1 {
  font-family: "Lato", sans-serif;
  font-weight: 200;
  font-size: 1.5em;
  letter-spacing: 0.15em;
  color: #333;
}
.details-hotel {
  width: 95%;
  margin: 0 auto 2em;
  padding-top: 11em;
  padding-bottom: 1em;
  background-color: #efddf4;
  color: #333;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 5px rgba(0, 0, 0, 0.24);
  transition: all ease-in 0.15s;
}

.wrapper-hotel:hover .details-hotel {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 5px rgba(0, 0, 0, 0.22);
  transform: translate(0px, -2px) scale(1.01);
}

.details-hotel  > h1 {
  font-family: "Playfair Display";
  font-size: 1em;
}
.details-hotel  > h2 {
  font-family: sans-serif;
  font-size: 1em;
  font-weight: 200;
  letter-spacing: 0.1em;
}
.details-hotel  > p {
  font-family: sans-serif;
  font-size: 1em;
  font-weight: 200;
}

.image-hotel {
  position: relative;
  z-index: 2;
  width: 85%;
  height: 150px;
  margin: 0 auto -10em;
  background-size: cover;
  background-position: center;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 10px rgba(0, 0, 0, 0.24);
  transition: all ease-in 0.15s;
}
.wrapper:hover .image-hotel {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  transform: translate(0px, -3px) scale(1.02);
}

.i1 {
  background-image: url(https://static3-clubmahindra.gumlet.io/storage/app/media/All%20Compressed%20banners/Club%20Mahindra%20Tungi%20Lonavala.jpg?w=720&dpr=1.5);
}
.i2 {
  background-image: url(https://static3-clubmahindra.gumlet.io/storage/app/media/All%20Compressed%20banners/Club-Mahindra-Assonora.jpg?w=720&dpr=1.5);
}
.i3 {
  background-image: url(https://static2-clubmahindra.gumlet.io/storage/app/media/All%20Compressed%20banners/Club-Mahindra-Ashtamudi-Resized.jpg?w=720&dpr=1.5);
}

@media screen and (max-width: 1000px) {
  .wrapper-hotel {
    width: 47%;
  }
}
@media screen and(max-width:700px) {
  .wrapper-hotel {
    float: none;
    width: 70%;
    margin: 1em auto;
  }
}
@media screen and(max-width:700px) {
  .wrapper-hotel {
    float: none;
    width: 70%;
    margin: 1em auto;
  }
}
