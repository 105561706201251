:root .prod-sidebar-sbi {
  position: absolute;
  right: 0px;
  background: rgb(255, 255, 255);
  z-index: 12;
  width: 350px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  overflow: hidden;
  margin: 10px;
  margin-top: 0px;
  height: 96vh;
  border-radius: 10px;
  border: 1px solid lightgray;
}

:root .accordian {
  color: red;
  padding: 0px;
}
:root .accordion-button {
  font-size: 14px;
  height: 35px;
  /* // color: rgb(132, 132, 132); */
  color: #241a76;
  background-color: transparent;
  /* // background-color: var(--datageir-theme-bg-color); */
}

:root .accordion-button:not(.collapsed) {
  font-size: 14px;
  height: 35px;
  background-color: transparent;
  /* // background-color: #e2eef8; */
  font-weight: 500;
  color: #241a76;
}
