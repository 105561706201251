.chat-container {
  /* margin-left: 370px; */
  /* margin-right: 10px; */
  /* margin-bottom: 10px; */
  /* margin-top: 10px; */
  /* background-image: url("../assets/images/home_bg.avif"); */
  text-align: left;
  transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
  /* object-fit: contain; */
  height: 90vh;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding-top: 20px;
  width: 100%;

  border: 1px solid lightgray;

  padding-bottom: 80px;
  overflow-y: scroll;
}

.chat-container-search {
  /* margin-left: 370px; */
  /* margin-right: 10px; */
  /* margin-bottom: 10px; */
  /* margin-top: 10px; */
  /* background-image: url("../assets/images/home_bg.avif"); */
  text-align: left;
  transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
  /* object-fit: contain; */
  height: 90vh;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding-top: 60px;
  width: 100%;

  border: 1px solid lightgray;

  padding-bottom: 80px;
  overflow-y: scroll;
}

:root .new-input-form {
  width: 100%;
  padding-bottom: 20px;
  z-index: 11;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  align-items: center;
  backdrop-filter: blur(7px);
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
}

.rolling-chat-new {
  cursor: pointer;
  width: 30px;
  height: 30px;
  text-align: left;
  overflow: hidden;
  transition: all 0.5s ease;
  white-space: nowrap;
  margin: 0px;
  color: #f77e34;
}
.rolling-chat-new:hover {
  width: 110px;
  display: flex;
  align-items: center;
  transition: all 0.5s ease;
}
.rolling-chat-new ::after {
  font-weight: bold;
  padding: 4px 0;
}
.rolling-chat-new:hover {
  display: block;
}
.rolling-buttons-new {
  cursor: pointer;
  width: 32px;
  height: 32px;
  text-align: left;
  overflow: hidden;
  transition: all 0.5s ease;
  white-space: nowrap;
  margin: 0px;
}
.rolling-buttons-new:hover {
  width: 100px;
  transition: all 0.5s ease;
}

/* animation */

.scale-up-center {
  -webkit-animation: scale-up-center 300ms ease-in-out forwards;
  animation: scale-up-center 300ms ease-in-out forwards;
}
/* @-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
} */
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

/* ChatBubble.css */
.chat-bubble {
  overflow-y: auto;
  height: 30px;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  backdrop-filter: blur(2.7px);
}

.chat-message {
  /* background-color: rgba(241, 240, 240, 1); */
  background-color: white;
  border-radius: 15px;
  padding: 5px 10px;
  margin-bottom: 5px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  /* margin-left: 20px; */
  max-width: 80%;
}

.chat-message.user {
  background: linear-gradient(
    135deg,
    rgba(0, 107, 178, 1) 0%,
    rgba(52, 158, 211, 1) 90%
  );
  backdrop-filter: blur(2.7px);
  color: #fff;
  align-self: flex-end;
}

.chat-message.error {
  background: white;
  color: #f57113;
  align-self: flex-end;
  border: 1px solid red;
  backdrop-filter: blur(2.7px);
  font-size: 14px;
}

.chat-message.other {
  /* background: rgba(236, 243, 254, 1); */
  background: white;
  color: #086eb5;
  align-self: flex-end;
  backdrop-filter: blur(2.7px);
  border: 1px solid #eef4f8;
}

.chat-response {
  margin-bottom: 5px;
  max-width: 100%;
  justify-content: end;
  /* display: flex; */
}

.avatar {
  box-shadow: rgba(28, 28, 50, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.audio-message {
  /* background-color: rgba(241, 240, 240, 1); */
  background-color: white;
  border-radius: 5px;
  padding: 5px 10px;
  margin-bottom: 5px;
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, */
  /* rgba(0, 0, 0, 0.3) 0px 3px 7px -3px; */
  /* margin-left: 20px; */
  /* color: #178717; */
  color: #7c0d69;
}

.transcript-message {
  /* background-color: rgba(241, 240, 240, 1); */
  background-color: white;
  border-radius: 5px;
  padding: 5px 10px;
  margin-bottom: 5px;
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, */
  /* rgba(0, 0, 0, 0.3) 0px 3px 7px -3px; */
  /* margin-left: 20px; */
  color: #437ddb;
}

span.Typewriter__wrapper {
  font-weight: 400;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.bottom-to-up {
  animation: 0.4s bottom-to-up forwards;
}

@keyframes bottom-to-up {
  from {
    transform: translateY(48px);
  }

  to {
    transform: translateX(0);
  }
}

.right-to-left {
  animation: 0.4s left-to-right forwards;
}

@keyframes right-to-left {
  from {
    transform: translateX(-100vw);
  }

  to {
    transform: translateX(0);
  }
}

.left-to-right {
  animation: 0.4s right-to-left forwards;
}

@keyframes left-to-right {
  from {
    transform: translateX(100vw);
  }

  to {
    transform: translateX(0);
  }
}

.top-to-bottom {
  animation: 0.4s top-to-bottom forwards;
}

@keyframes top-to-bottom {
  from {
    transform: translateY(-48px);
  }

  to {
    transform: translateY(0);
  }
}

/* jsw */

.chat-message-jsw {
  background-color: #f1f0f0;
  border-radius: 10px;
  padding: 8px 12px;
  margin-bottom: 5px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  margin-left: 20px;
}

.chat-message-jsw.other {
  background-color: white;
  color: #086eb5;
  align-self: flex-end;
  border: 1px solid white;
}

.chat-message-jsw.user {
  /* background: linear-gradient(to left, #0fa3ff 10%, #005bea 90%); */
  background-color: rgb(186, 211, 255);
  /* border: 0.5px solid rgb(23,70,158); */
  backdrop-filter: blur(2.7px);
  color: #2b2b2b;
  align-self: flex-end;
  background-image: linear-gradient(
    120deg,
    rgb(186, 211, 255) 0%,
    #c2e9fb 100%
  );
}

:root .nav-link-jsw {
  color: rgb(23, 70, 158);
}
:root .nav-link-jsw:active {
  color: rgb(23, 70, 158);
}
:root .offcanvas-header {
  background-color: rgb(192 230 255);
  padding: 10px;
}

.card-input-element {
  display: none;
  white-space: nowrap;
  text-wrap: nowrap;
}
.card-input:hover {
  cursor: pointer;
  box-shadow: 0 0 1px 1px #19a3526d;
}

.card-input-element:checked + .card-input {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 2px -2px,
    rgba(0, 0, 0, 0.3) 0px 2px 4px -3px;
  /* background: #f77f3418; */
  color: #f77e34 !important;
  border: 1px solid #f77e34;
}
.card-input-element:disabled + .card-input {
  opacity: 0.7;
}

.zoom-range[type="range"]::-webkit-slider-thumb {
  background: rgb(128, 106, 198); /* Change to desired color */
  cursor: pointer;
}
.zoom-range[type="range"]::-webkit-slider-runnable-track {
  background-color: #f9f9f9; /* Default background color */
}

:root .custom-tooltip {
  --cui-tooltip-bg: white;
  --cui-tooltip-color: var(--aiguide-dark-text-color);
  --cui-tooltip-padding-x: 5px;
  --cui-tooltip-padding-y: 5px;
  --cui-tooltip-max-width: 15rem;
  --cui-tooltip-border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.44) 0px 3px 8px;
  border: 1px solid rgb(220, 220, 220);
  border-radius: 5px;
  z-index: 2080 !important;
  font-size: 12px;
}

.font-size-input[type="number"]::-webkit-inner-spin-button,
.font-size-input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0px;
}

.font-style-select {
  height: 26px;
  box-shadow: none;
  border-radius: 5px;
  border: 1px solid rgb(229, 229, 229);
}

.font-style-select:focus-visible {
  outline: none;
}

.prompt-card {
  background-color: #f6f6f6;
  color: #828282;
  border-radius: 10px;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
  cursor: pointer;
}
.prompt-card:hover {
  background-color: #e8e8e8;
  color: #505050;
}
.prompt-card-active {
  background-color: #e8e8e8;
  color: #505050;
}
