.chat-body {
  background-color: white;
  margin-left: 250px;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 10px;
  overflow-y: auto;
  height: 90vh;
  padding-bottom: 80px;
}
.generate-button {
  color: white;
  background: linear-gradient(
    to bottom,
    rgba(27, 181, 27, 1) 0%,
    rgba(74, 178, 74, 1) 100%
  );
  border: rgba(3, 163, 3, 0.87);
}
.generate-button:hover {
  color: white;
  background: linear-gradient(
    to bottom,
    rgba(27, 181, 27, 1) 0%,
    rgba(74, 178, 74, 1) 100%
  );
  border: rgba(3, 163, 3, 0.76);
}
.generate-button:disabled {
  color: white;
  background: linear-gradient(
    to bottom,
    rgba(27, 181, 27, 1) 0%,
    rgba(74, 178, 74, 1) 100%
  );
  border: rgba(3, 163, 3, 0.76);
}
.generate-button:active {
  color: white;
  background: linear-gradient(
    to bottom,
    rgba(27, 181, 27, 1) 0%,
    rgba(74, 178, 74, 1) 100%
  );
  border: rgb(3, 163, 3);
}
.url-form {
  position: absolute;
  width: 84%;
  bottom: 20px;
}
.chat-background {
  /* background-image: url("../assets/images/chat-bg.svg"); */
  background: linear-gradient(135deg, #f8fff3 1%, hsl(0, 0%, 88%) 100%);
}
