.custom-loader {
    width:25px;
    height:6px;
    background: radial-gradient(circle closest-side,#373795 90%,#0000) 0 0/33% 100% space;
    clip-path: inset(0 100% 0 0);
    animation:d1 2s steps(4) infinite;
  }
  @keyframes d1 {to{clip-path: inset(0 -34% 0 0)}}

  .typewriter div {
    overflow: hidden; /* Ensures the content is not revealed until the animation */
    white-space: wrap; /* Keeps the content on a single line */
    text-align: left;
    animation: 
      typing 2.5s steps(40, end),
      blink-caret .75s step-end infinite;
  }
  
  /* The typing effect */
  @keyframes typing {
    from { width: 0 }
    to { width: 100% }
  }
  
  /* The typewriter cursor effect */
  @keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: orange; }
  }