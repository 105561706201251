:root .guide-sidebar {
  background: rgb(255, 255, 255);
  z-index: 12;
  width: 250px;
  padding: 10px;
  box-shadow: 0 0 6px rgba(102, 102, 102, 0.75);
  clip-path: inset(0px -15px 0px 0px);
}
:root .image-sidebar {
  background: rgb(255, 255, 255);
  z-index: 12;
  padding: 10px;
  box-shadow: 0 0 6px rgba(102, 102, 102, 0.75);
  clip-path: inset(0px -15px 0px 0px);
}
:root .url-sidebar {
  background-color: rgb(235, 251, 255);
  height: 100vh;
  color: white;
  overflow: hidden;
  position: fixed;
  left: 0;
  bottom: 0;
  top: 0;
  right: auto;
  z-index: 12;
  width: 300px;
  padding: 20px;
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px; */
}

.sidemenu-navitem-bold {
  font-size: var(--datageir-medium-font-size);
  color: #3c4854;
  background-color: #f7f7f7;
}
:root .sidemenu-navitem-bold > * {
  padding: 5px 10px 5px 20px;
  color: #3c4854;
  background-color: #f7f7f7;
}
:root .sidemenu-navitem-bold :hover {
  background-color: #f7f7f7;
  color: #3c4854;
}
:root .sidemenu-navitem-bold .active {
  border-right: 4px solid #3399ff;
  margin-right: 4px;
}
:root .logout-button {
  color: #f77e34;
  border: 1px solid #f77e34;
  padding-top: 2px;
  padding-bottom: 2px;
  transition: ease-out 0.3s;
  outline: none;
  position: relative;
  z-index: 1;
}
:root .logout-button:hover {
  color: #eef4f8;
  background: none;
  border: 1px solid #f77e34;
}
:root .logout-button::before {
  transition: 0.5s all ease;
  position: absolute;
  top: 0;
  left: 50%;
  right: 50%;
  bottom: 0;
  opacity: 0;
  content: "";
  background-color: #f77e34;
  border-radius: 30px;
}
:root .logout-button:hover::before {
  transition: 0.5s all ease;
  left: 0;
  right: 0;
  opacity: 1;
  z-index: -1;
}
:root .logout-button:disabled {
  color: rgba(247, 127, 52, 0.9);
  border: 1px solid rgba(247, 127, 52, 0.9);
}

:root .logout-button-jsw {
  color: #eef4f8;
  border: 1px solid rgb(23, 70, 158);
  background-color: rgb(23, 70, 158);
  padding-top: 2px;
  padding-bottom: 2px;
  transition: ease-out 0.3s;
  outline: none;
  position: relative;
  z-index: 1;
}
:root .logout-button-jsw:hover {
  color: #eef4f8;
  background: none;
  border: 1px solid rgb(23, 70, 158);
}
:root .logout-button-jsw::before {
  transition: 0.5s all ease;
  position: absolute;
  top: 0;
  left: 50%;
  right: 50%;
  bottom: 0;
  opacity: 0;
  content: "";
  background-color: rgb(23, 70, 158);
  border-radius: 30px;
}
:root .logout-button-jsw:hover::before {
  transition: 0.5s all ease;
  left: 0;
  right: 0;
  opacity: 1;
  z-index: -1;
}
:root .logout-button-jsw:disabled {
  color: rgba(248, 250, 255, 0.9);
  border: 1px solid rgba(23, 70, 158, 0.9);
}
:root .new-chat {
  background-color: #ff8330;
  color: #eef4f8;
  border: 1px solid #ff8330;
  padding-top: 2px;
  padding-bottom: 2px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
:root .new-chat:hover {
  background-color: rgba(249, 135, 59, 0.9);
  border: 1px solid rgba(249, 135, 59, 0.9);
  color: #eef4f8;
}
.zoom {
  transition: transform 0.2s; /* Animation */
  width: 200px;
  margin: 0 auto;
}

.zoom:hover {
  transform: scale(
    1.1
  ); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}
.logout-icon {
  transition: all 0.3s ease;
}
.logout-icon:hover {
  color: #ff8330;
  transition: all 0.3s ease;
  transform: scale(1.2);
}
.logout-icon-2 {
  transition: all 0.3s ease;
  color: white;
}
.logout-icon-2:hover {
  color: #ff8330;
  transition: all 0.3s ease;
  transform: scale(1.2);
}
.pop-button {
  transition: all 0.5s ease;
}
.pop-button:hover {
  transition: all 0.5s ease;
  transform: translateY(-5px);
}

.file-input__input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.file-input__label {
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 40px;
  font-size: 12px;
  font-weight: 500;
  color: #ff8330;
  padding: 3px 12px;
  background-color: white;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  border: 0.5px solid #ff8330;
  width: 100%;
  justify-content: center;
  /* margin: auto; */
}

.file-input__label svg {
  height: 14px;
  margin-right: 6px;
}
.rolling-chat {
  cursor: pointer;
  width: 32px;
  height: 32px;
  text-align: left;
  overflow: hidden;
  transition: all 0.5s ease;
  white-space: nowrap;
  margin: 0px;
  color: #f77e34;
}
.rolling-chat:hover {
  width: 100px;
  transition: all 0.5s ease;
}
.rolling-chat ::after {
  font-weight: bold;
  padding: 4px 0;
}
.rolling-chat:hover {
  display: block;
}
.rolling-buttons {
  cursor: pointer;
  width: 32px;
  height: 32px;
  text-align: left;
  overflow: hidden;
  transition: all 0.5s ease;
  white-space: nowrap;
  margin: 0px;
}
.rolling-buttons:hover {
  width: 100px;
  transition: all 0.5s ease;
}

:root .prod-sidebar-jsw {
  background: rgb(255, 255, 255);
  z-index: 10;
  width: 250px;
  padding: 10px;
  box-shadow: 0 0 6px rgba(102, 102, 102, 0.75);
  clip-path: inset(0px -15px 0px 0px);
  overflow: hidden;
}

:root .conv-hover:hover {
  background: rgba(186, 211, 255, 0.3);
  border-color: rgba(186, 211, 255, 0.8);
}

:root .delete-button {
  color: rgb(204, 51, 0);
  border: 1px solid rgb(204, 51, 0);
  padding-top: 2px;
  padding-bottom: 2px;
  transition: ease-out 0.3s;
  outline: none;
  position: relative;
  z-index: 1;
}
:root .delete-button:hover {
  color: #eef4f8;
  background: none;
  border: 1px solid rgb(204, 51, 0);
}
:root .delete-button::before {
  transition: 0.5s all ease;
  position: absolute;
  top: 0;
  left: 50%;
  right: 50%;
  bottom: 0;
  opacity: 0;
  content: "";
  background-color: rgb(204, 51, 0);
  border-radius: 30px;
}
:root .delete-button:hover::before {
  transition: 0.5s all ease;
  left: 0;
  right: 0;
  opacity: 1;
  z-index: -1;
}
:root .delete-button:disabled {
  color: rgba(204, 51, 0, 0.9);
  border: 1px solid rgba(204, 51, 0, 0.9);
}

:root .hamburger-button {
  border: 1px solid rgb(23, 70, 158);
}
:root .hamburger-button:hover {
  background-color: rgb(23, 70, 158);
  color: white;
}
:root .hamburger-icon {
  color: inherit;
}
:root .hamburger-button-active {
  background-color: rgb(23, 70, 158);
  color: white;
}
:root .hamburger-button:focus {
  background-color: rgb(23, 70, 158);
  color: white;
}
