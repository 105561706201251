.link-card {
  background-color: #eef4f8;
}
.link-card:hover {
  border: 1px solid #f26304;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
.ag-format-container {
  width: 90%;
  margin: 0 auto;
}

.ag-courses_box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-top: 20px;
}
.ag-courses_item {
  -ms-flex-preferred-size: calc(33.33333% - 30px);
  flex-basis: calc(33.33333% - 30px);
  position: relative;
  margin: 0 15px 20px;
  cursor: pointer;
  height: 110px;
  border-radius: 15px;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 1);
  transition: all 0.5s ease;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  backdrop-filter: blur(5px);
}
.ag-courses-item_link {
  display: block;
  padding: 10px;
  position: relative;
}
.ag-courses-item_link:hover,
.ag-courses-item_link:hover .ag-courses-item_date {
  text-decoration: none;
  color: black;
}
.ag-courses_item:hover {
  /* transform: scale(1.05); */
  transform: translateY(-7px) scale(1.05);
}
/* .ag-courses_item:hover .ag-courses-item_bg {
  transform: scale(10);
} */
.ag-courses-item_title {
  /* min-height: 70px; */
  overflow: hidden;
  font-weight: 600;
  font-size: 14px;
  color: rgb(245, 89, 32);
  z-index: 2;
  position: relative;
}
.ag-icons {
  position: absolute;
  right: 7px;
  top: 7px;
  color: white;
  z-index: 3;
}
.ag-courses-item_date-box {
  font-size: 12px;
  /* color: rgb(13, 98, 188); */
  color: rgb(47 32 95);

  z-index: 2;
  position: relative;
  font-weight: 500;
}
.ag-courses-item_date {
  color: #f9b234;
  -webkit-transition: color 0.5s ease;
  -o-transition: color 0.5s ease;
  transition: color 0.5s ease;
  font-weight: 600;
}
.ag-courses-item_bg {
  height: 90px;
  width: 90px;
  background-color: rgb(139 123 192);
  /* rgb(172, 103, 242, 0.9); */
  z-index: 1;
  position: absolute;
  top: -50px;
  right: -50px;
  border-radius: 50%;

  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.fixed-alert {
  /* color: rgb(143, 53, 227); */
  color: rgb(47 32 95);
  background-color: rgb(252 248 244);
  /*rgba(143, 53, 227, 0.1); */
  font-family: "system-ui",
}
.added-alert {
  color: rgb(137, 137, 137);
  background-color: rgba(218, 218, 218, 0.772);
  font-size: 10px;
}

.improvement-alert {
  color: rgb(7, 71, 166);
  background-color: rgba(7, 71, 166, 0.1);
}
.release-alert {
  font-weight: 500;
  padding: 2px 6px;
  width: fit-content;
  border-radius: 5px;
  font-size: 11px;
  font-family: "system-ui";

}

@media only screen and (max-width: 979px) {
  .ag-courses_item {
    -ms-flex-preferred-size: calc(50% - 30px);
    flex-basis: calc(50% - 30px);
  }
  .ag-courses-item_title {
    font-size: 24px;
  }
}

@media only screen and (max-width: 767px) {
  .ag-format-container {
    width: 96%;
  }
}
@media only screen and (max-width: 639px) {
  .ag-courses_item {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }
  .ag-courses-item_title {
    min-height: 72px;
    line-height: 1;

    font-size: 24px;
  }
  .ag-courses-item_link {
    padding: 22px 40px;
  }
  .ag-courses-item_date-box {
    font-size: 16px;
  }
}

.homepage-title {
  animation: fadeInAnimation ease 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
