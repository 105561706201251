/* ChatBubble.css */
.chat-bubble {
  overflow-y: auto;
  height: 30px;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
}

.chat-message {
  background-color: #f1f0f0;
  border-radius: 10px;
  padding: 8px 12px;
  margin-bottom: 5px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  margin-left: 20px;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  text-wrap: wrap;
  font-size: 12px;
}
.chat-animation {
  animation: fadeInAnimation ease 1.6s;
}
.chat-transition-user {
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation: 1s from-right forwards;
}
.chat-transition-bot {
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation: 1s from-left forwards;
}
@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes from-left {
  from {
    transform: translateY(-20px);
  }

  to {
    transform: translateY(0);
  }
}
@keyframes from-right {
  from {
    transform: translateY(-20px);
  }

  to {
    transform: translateY(0);
  }
}
.chat-message.user-sbi {
  background: linear-gradient(
    135deg,
    rgba(13, 140, 191, 1) 0%,
    rgba(107, 204, 249, 1) 100%
  );
  color: #fff;
  align-self: flex-end;
}

.chat-message.error-sbi {
  background-color: white;
  color: rgb(254, 109, 69);
  align-self: flex-end;
  border: 1px solid rgb(254, 109, 69);
}
.chat-message.other-sbi {
  background: linear-gradient(
    135deg,
    rgb(132, 32, 255) 0%,
    rgb(156, 126, 255) 100%
  );
  color: white;
  align-self: flex-end;
  border: none;
}
.chat-response {
  margin-bottom: 5px;
  max-width: 100%;
  justify-content: flex-end;
  display: flex;
}
.bottom-to-up {
  animation: 1s bottom-to-up forwards;
}

@keyframes bottom-to-up {
  from {
    transform: translateY(48px);
  }

  to {
    transform: translateX(0);
  }
}

.right-to-left {
  animation: 1s left-to-right forwards;
}

@keyframes right-to-left {
  from {
    transform: translateX(-100vw);
  }

  to {
    transform: translateX(0);
  }
}

.left-to-right {
  animation: 1s right-to-left forwards;
}

@keyframes left-to-right {
  from {
    transform: translateX(100vw);
  }

  to {
    transform: translateX(0);
  }
}

.top-to-bottom {
  animation: 1s top-to-bottom forwards;
}

@keyframes top-to-bottom {
  from {
    transform: translateY(-48px);
  }

  to {
    transform: translateY(0);
  }
}
.loader {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: block;
  margin: 15px auto;
  margin-left: 10px;
  position: relative;
  color: #3f8e3f;
  box-sizing: border-box;
  animation: animloader 2s linear infinite;
}

@keyframes animloader {
  0% {
    box-shadow: 14px 0 0 -2px, 38px 0 0 -2px, -14px 0 0 -2px, -38px 0 0 -2px;
  }
  25% {
    box-shadow: 14px 0 0 -2px, 38px 0 0 -2px, -14px 0 0 -2px, -38px 0 0 2px;
  }
  50% {
    box-shadow: 14px 0 0 -2px, 38px 0 0 -2px, -14px 0 0 2px, -38px 0 0 -2px;
  }
  75% {
    box-shadow: 14px 0 0 2px, 38px 0 0 -2px, -14px 0 0 -2px, -38px 0 0 -2px;
  }
  100% {
    box-shadow: 14px 0 0 -2px, 38px 0 0 2px, -14px 0 0 -2px, -38px 0 0 -2px;
  }
}
