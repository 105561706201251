:root .new-sidebar {
  /* left: 10px; */
  background: rgb(255, 255, 255);
  z-index: 12;
  width: 100%;
  overflow: hidden;
  /* margin: 10px;
    margin-top: 10px; */

  height: 90vh;
  border-radius: 10px;
}
.title-sidebar {
  color: #f88130;
  font-size: 16px;
  margin-left: 10px;
  font-weight: 500;
  font-family: system-ui;
}

.side-tab {
  font-size: 12px;
  text-align: center;
  max-height: fit-content;
  color: rgb(145, 145, 145);
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  padding: 5px;
  margin-top: 3px;
  border-radius: 10px;
}

.side-tab span {
  color: rgb(145, 145, 145);
}
.side-tab:hover {
  background-color: rgba(84, 58, 170, 0.1);
  color: rgba(84, 58, 170, 0.661);
  .side-tab-span {
    color: rgba(84, 58, 170, 0.661);
  }
}

.side-tab-active {
  background-color: rgba(84, 58, 170, 0.1);
  color: rgb(84, 58, 170);
  .side-tab-span {
    color: rgb(84, 58, 170);
  }
}

.side-tab-container {
  max-width: 6% !important;
  height: 93vh;
  background-color: rgb(255, 255, 255);
  border-right: 1px solid rgb(231, 231, 231);
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.side-tab-nested-container {
  max-width: 26% !important;
  height: 93vh;
  background: rgb(255, 255, 255);
  overflow-y: scroll;
  position: relative;
  border-right: 1px solid rgb(231, 231, 231);
}

.side-tab-nested-content {
  background-color: white;
  color: rgb(86, 86, 86);
  max-height: 30px;
  position: sticky;
  top: 0px;
  font-size: 15px;
  font-weight: 600;
  z-index: 1;
  border-bottom: 1px solid rgb(231, 231, 231);
}

.side-tab-main-content {
  max-width: 67.4%  !important;
  height: 93vh;
  background: rgb(255, 255, 255);
}
