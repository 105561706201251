.navbar-light {
  height: 40px;
  background-color: rgb(139 123 192);
  /* box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2); */
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 5px;
}

.logout-icon-2 {
  transition: all 0.3s ease;
  color: white;
}
.logout-icon-2:hover {
  color: #ff8330;
  transition: all 0.3s ease;
  transform: scale(1.2);
}
