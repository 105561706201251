:root {
  --datageir-scrollbar-size: 5px;
  --datageir-alert-height: 40px;
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(135, 206, 235,0.7);
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: lightblue;
}
:root .scrollbar-jsw::-webkit-scrollbar-thumb{
  background-color: rgba(23,70,158,0.7);
}
:root .scrollbar-jsw::-webkit-scrollbar-thumb:hover{
  background-color: rgba(23,70,158,0.8);
}
